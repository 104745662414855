import Vue from 'vue';
import {filterVIN,} from '../../../../services/endpoint.json';
import {post} from '@/services/api';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import VueRellax from 'vue-rellax';
import optionsPage from '../options/index.vue';
import OrderHistory from '@/views/dashboard/create-software-order/mainscreen/OrderHistory';
import CurrencyPriceView from '@/components/common/CurrencyPriceView';


Vue.use(VueRellax);

export default Vue.extend({
  components: {
    optionsPage,
    OrderHistory,
    CurrencyPriceView
  },
  name: 'create-software-order',
  data: () => ({
    backtoButton: false,
    showOptions: false,
    maxTimer: 60,
    currentTimer: 0,
    previousPrice: 0,
    disabledInput: false,
    gearboxParts: [],
    vinSearch: '',
    alertMessage: false,
    focused: false,
    focused_dsg: false,
    historyOfVin: [],
    sectionOrder: '',
    optionPreSelected: null,
    combos: [],
    showCombosSecction: false,
    showManualSearch: false,
    showDsgEngineSection: false,
    foundgearbox: false,
    showNotAvailable: false
  }),
  mounted() {
    this.clearStorageCar()
  },
  computed: {
    ...mapState('orderSoftware', ['brands', 'models', 'engines', 'generations', 'filters', 'combosTunning', 'dsgTunning', 'sofwareTunning', 'manualSearch', 'cart', 'autoSearch', 'arrayGearbox']),
    ...mapState('buySale', ['information', 'file_server_enabled']),
    ...mapGetters('geo', ['userCountryPrice']),
    ...mapGetters('geo', ['currencyToUser']),
    parameters: {
      get() {
        return this.$store.state.orderSoftware.params;
      },
      set(value) {
        this.$store.commit('orderSoftware/setParameters', value);
      }
    },
    filter_brand: {
      get() {
        return this.$store.state.orderSoftware.filters.brand;
      },
      set(value) {
        this.optionPreSelected = null;
        this.showOptions = false;
        this.$store.commit('orderSoftware/updateFilter', {
          key: 'brand',
          value
        });
        this.$store.dispatch('orderSoftware/load', 'models');
        this.showDsgEngineSection = false;
        this.showNotAvailable = false;
      }
    },
    filter_model: {
      get() {
        return this.$store.state.orderSoftware.filters.model;
      },
      set(value) {
        this.optionPreSelected = null;
        this.showOptions = false;
        this.$store.commit('orderSoftware/updateFilter', {
          key: 'model',
          value
        });
        this.$store.dispatch('orderSoftware/load', 'generations');
        this.showDsgEngineSection = false;
        this.showNotAvailable = false;
      }
    },
    filter_generation: {
      get() {
        return this.$store.state.orderSoftware.filters.generation;
      },
      set(value) {
        this.optionPreSelected = null;
        this.showOptions = false;
        this.$store.commit('orderSoftware/updateFilter', {
          key: 'generation',
          value
        });
        this.$store.dispatch('orderSoftware/load', 'engines');
        this.showDsgEngineSection = false
        this.showNotAvailable = false;
      }
    },
    filter_engine: {
      get() {
        return this.$store.state.orderSoftware.filters.engine;
      },
      set(value) {
        this.$store.commit('orderSoftware/cleanResult');
        this.optionPreSelected = null;
        this.showOptions = false;
        this.showDsgEngineSection = false
        this.showNotAvailable = false;

        if (this.engines.length && this.engines[0].generation) {
          this.$store.state.orderSoftware.filters.generation = this.engines.find(el => el.engine === value).generation;
        }
        this.$store.commit('orderSoftware/updateFilter', {
          key: 'engine',
          value
        });
        this.$store.commit('orderSoftware/clearGearbox')
        this.focused = false;
        this.vehicleOptions(true)
          .then((result) => {
            this.showManualSearch = result;
            if (result) {
              this.showNotAvailable = true;
              this.showDsgEngineSection = true;
              this.scrollElement('result-vin')
            } else {
              this.foundgearbox = true;
              this.showNotAvailable = false;
            }
          });
      },
    },
    filter_gearbox: {
      get() {
        return this.$store.state.orderSoftware.filters.gearbox
      },
      set(value) {
        this.$store.commit('orderSoftware/cleanResult');
        this.$store.commit('orderSoftware/updateFilter', {
          key: 'gearbox',
          value
        });
        this.showOptions = false;
        this.foundgearbox = false;
        this.showDsgEngineSection = false
        this.showNotAvailable = false;

        if (value) {
          this.vehicleOptions(false)
            .then(() => {
              this.showManualSearch = true;
              this.showDsgEngineSection = false
              this.showNotAvailable = true;
              this.scrollElement('result-vin')
            });
        } else {
          this.$store.commit('orderSoftware/clearResult')
          this.showDsgEngineSection = false
        }
      }
    },
    searchTypeText() {
      return this.manualSearch ? 'Switch to auto search' : 'Switch to manual search';
    },
    isVinFound() {
      return this.cart.vehicleId !== null && this.cart.vehicleId.hasOwnProperty('ecu_code');
    },
  },
  methods: {
    ...mapActions('orderSoftware', ['vehicleOptions']),
    ...mapMutations('orderSoftware', ['switchSearchType']),
    scrollElement(element) {
      document.getElementById(element)
        .scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        });
    },
    clearStorageCar(){
      localStorage.removeItem('cart');
      localStorage.removeItem('admin-software');
      localStorage.removeItem('admin-dsg');
      this.$store.commit('orderSoftware/clear');
      this.$store.commit('orderSoftware/resetFullOrderSoftwareState');
    },
    toogleBackButton() {
      this.scrollElement('beforeStages');
      if (this.backtoButton === false) {
        document.getElementById('myBtn')
          .classList
          .remove('reappear');
        document.getElementById('myBtn')
          .classList
          .add('dissapear');
      } else {
        document.getElementById('myBtn')
          .classList
          .remove('dissapear');
        document.getElementById('myBtn')
          .classList
          .add('reappear');
      }
      this.backtoButton = !this.backtoButton;
    },
    elementHighlight(element) {
      const ecuBox = document.getElementById(`ecuStageBox${element.ecuObject.id}`);
      if (ecuBox) {
        ecuBox.classList.add('enlighten');
      }
      const dsgBox = document.getElementById(`dsgStageBox${element.dsgObject.id}`);
      if (dsgBox) {
        dsgBox.classList.add('enlighten');
      }
    },
    removeElementHighlight(element) {
      const ecuBox = document.getElementById(`ecuStageBox${element.ecuObject.id}`);
      if (ecuBox) {
        ecuBox.classList.remove('enlighten');
      }
      const dsgBox = document.getElementById(`dsgStageBox${element.dsgObject.id}`);
      if (dsgBox) {
        dsgBox.classList.remove('enlighten');
      }
    },
    preShowDetail(type, item) {
      this.showOptions = false;
      this.optionPreSelected = {
        type: type,
        ...item
      };
      this.setCombos();
    },
    showDetail(type, id, scroll = 'optionsP') {
      this.params = {
        type,
        id,
      };
      this.showOptions = true;
      this.backtoButton = true;
      this.toogleBackButton();
      this.$nextTick(() => {
        setTimeout(() => {
          this.scrollElement(scroll);
        }, 100);

        this.$store.commit('orderSoftware/setParameters', {
          'type': type,
          'id': id
        });
      });
    },
    async findByVIN(evento) {
      this.showNotAvailable = false;
      this.showDsgEngineSection = false;
      const vin = evento.toUpperCase();
      this.showOptions = false
      let {valid} = await this.$refs.validator.validate(evento);
      if (valid) {
        this.$store.commit('orderSoftware/clear');
        this.disabledInput = true;
        this.currentTimer = 0;
        this.showManualSearch = false;
        window.setInterval(this.intervalo, 1000);
        post('order-software/find-history-vin', {vin}, true)
          .then(({data}) => {
            if (data) {
              this.historyOfVin = [data].map((el) => ({
                ...el,
                description: JSON.parse(el.description)
              }));
            }
          });
        this.waitResponse(vin);
        post(filterVIN, {vin}, false).catch(() => {
          this.disabledInput = false;
          this.$swal({
            icon: 'info',
            text: 'VIN not found, please try manual search',
            confirmButtonColor: '#d14343',
            cancelButtonColor: '#000000',
            confirmButtonText: 'OK',
            cancelButtonText: 'No',
          });
          this.sockets.unsubscribe(vin);
        });
      }
    },
    waitResponse(vin) {
      this.sockets.subscribe(vin, async response => {
        this.disabledInput = false;
        if (response.result) {
          this.engineCode = response.result.engine_code;
          if (response.vehicles.length) {
            await this.fillFilterAndGetOptions(response);
          } else {
            showNotFound()
          }
        } else {
          showNotFound()
        }
        this.sockets.unsubscribe(vin);
      });

      const showNotFound = () => {
        this.$swal({
          icon: 'info',
          text: 'VIN not found, please try manual search',
          confirmButtonColor: '#d14343',
          cancelButtonColor: '#000000',
          confirmButtonText: 'OK',
          cancelButtonText: 'No',
        });
      }
    },
    async fillFilterAndGetOptions(response) {
      let vehicles = response.vehicles;
      let vehicle = vehicles[0];
      vehicle.dsg = vehicle.dsg.split(';').map(el => el.trim())

      let vehicleinfo = {
        id: vehicle.id,
        brand: vehicle.make,
        model: vehicle.model,
        generation: vehicle.generation,
        engine_family: vehicle.engineFamily,
        dsg: vehicles.length === 1 ? vehicle.dsg[0].split('_')[0] : ' -- ',
        dsg_tvs: vehicle.dsg[0],
        engine: vehicles.length === 1 ? vehicle.engine : ' -- ',
        ecu_code: response.result.ecu_code,
        gearbox_code: response.result.gearbox_code,
        transmission_code: response.result.transmission_code,
        dsg_code: response.result.dsg_code,
        engine_code: response.result.engine_code
      };
      this.$store.commit('orderSoftware/setVehicleInfo', {
        vin: this.vinSearch,
        vehicleId: vehicleinfo
      });
      if (vehicles.length == 1) {
        if (vehicle.dsg.length > 1) {
          this.$store.commit('orderSoftware/setFilter', {
            brand: vehicle.make,
            model: vehicle.model,
            generation: vehicle.generation,
            engine: vehicle.engine
          });
          await this.$store.dispatch('orderSoftware/loadDataFromFullFilter', true);
          const arrayGearbox = vehicle.dsg.map(el => {
            return {text: el.split('_')[0], value: el.trim()}
          })
          this.$store.commit('orderSoftware/setGearboxArray', arrayGearbox);
          this.$store.commit('orderSoftware/showManualSearch', true);
          this.foundgearbox = true;
        } else {
          this.$store.dispatch('orderSoftware/vehicleOptionsById', vehicle.id)
            .then();
          this.$nextTick(() => {
            this.scrollElement('result-vin');
          });
        }
      } else {
        this.focused = true;
        this.$store.commit('orderSoftware/setFilter', {
          brand: vehicle.make,
          model: vehicle.model,
          generation: vehicle.generation
        });
        await this.$store.dispatch('orderSoftware/loadDataFromFullFilter');

        let engines = vehicles.map(v => {
          return {engine: v.engine, generation: v.generation};
        });

        this.$store.commit('orderSoftware/setData', {
          key: 'engines',
          data: engines
        });

        this.$store.commit('orderSoftware/showManualSearch', true);
      }
    },
    intervalo() {
      this.currentTimer = this.currentTimer + 1;
    },
    hiddenDivOption() {
      this.showOptions = false;
    },
    loadFromHistoryOrder(order) {
      this.$store.dispatch('orderSoftware/setPreOrder', order);
      let params = this.$store.getters['orderSoftware/getPreorderParams']();
      this.showDetail(params.type, params.id);
    },
    isPreselected(item) {
      if (this.optionPreSelected) {
        return this.optionPreSelected.type == item.type && this.optionPreSelected.id == item.id;
      }
      return false;
    },
    setCombos: function () {
      if (this.combosTunning && this.checkIfHasCombo()) {
        const combos = this.combosTunning.combos;
        if (this.optionPreSelected) {
          let timer = 500;
          if (!this.combos.length) {
            timer = 0;
          }
          this.combos = [];
          setTimeout(() => {
            this.combos = combos.filter(combo => {
              const field = this.optionPreSelected.type;
              return combo[field] == this.optionPreSelected.id;
            });
            this.$store.commit('orderSoftware/setcombos', this.combos)
          }, timer);
        }
      }
    },
    checkIfHasCombo() {
      if (this.optionPreSelected) {
        const field = this.optionPreSelected.type;
        const combos = this.combosTunning.combos.filter(combo => combo[field] === this.optionPreSelected.id);
        this.showCombosSecction = combos.length;
        return this.showCombosSecction;
      }
      return false;
    },
    clearSearch() {
      this.historyOfVin = [];
      this.showOptions = false;
      this.showManualSearch = false;
      this.showDsgEngineSection = false;
      this.showNotAvailable = false;
      this.$store.commit('orderSoftware/clear');
      this.switchSearchType();
    },
  },
  watch: {
    combosTunning: {
      handler: function () {
        this.combos = [];
        this.optionPreSelected = null;
        this.showCombosSecction = false;
      },
      deep: true
    },
    vinSearch() {
      if (this.vinSearch.includes(' ')) {
        this.vinSearch = this.vinSearch.replace(' ', '');
      }
      this.combos = [];
      this.$store.commit('orderSoftware/clear');
      this.$store.commit('orderSoftware/showManualSearch');
      this.hiddenDivOption();
      this.historyOfVin = [];
      this.$store.dispatch('orderSoftware/setPreOrder');
      this.showNotAvailable = false;
    },
    combos: function (newValue, oldValue) {
      if (!this.optionPreSelected) {
        this.$nextTick(() => {
          oldValue.forEach(el => this.removeElementHighlight(el));
          newValue.forEach(el => this.elementHighlight(el));
        });
      }
    }
  },
  beforeMount() {
    window.addEventListener("keydown", function (e) {
      if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(e.code) > -1) {
        e.preventDefault();
      }
    }, false);
    this.clearSearch();
    this.$store.commit('orderSoftware/resetSearchType')
    this.$store.dispatch('orderSoftware/loadBrands');
    this.$store.dispatch('buySale/loadInformation');
  }
});
