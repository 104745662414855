<template>
  <div v-if="list.length" class="result-vin-history">
    <h4 class="text-white text-uppercase" v-b-toggle="`history`">
      Vin history
      <hr class="line">
    </h4>
    <b-collapse id="history">
      <b-row no-gutters>
        <b-col class="mx-lg-1">
          <label class="title-vin-result">Date </label>
        </b-col>
        <b-col class="mx-lg-1">
          <label class="title-vin-result">Type </label>
        </b-col>
        <span class="sep">|</span>
        <b-col class="mx-lg-1">
          <label class="title-vin-result">Stage</label>
        </b-col>
        <span class="sep">|</span>
        <b-col class="mx-lg-1" v-if="$checkSubUserPermission('dashboard.view-detail')">
          <label class="title-vin-result">Options</label>
        </b-col>
        <b-col v-if="$checkSubUserPermission('dashboard.view-detail')">
          <label>Use history</label>
        </b-col>
      </b-row>
      <template v-for="(item) of list">
        <b-row no-gutters>
          <b-col class="mx-lg-1">
            <p class="sub-vin-result">{{ item.createdAt|localeDate }}</p>
          </b-col>
          <b-col class="mx-lg-1">
            <p class="sub-vin-result">{{ item.type }} <span v-if="item.type === 'ECU + DSG'"> (COMBO)</span></p>
          </b-col>
          <span class="sep">|</span>
          <b-col class="mx-lg-1">
            <p class="sub-vin-result">{{ item.stage | stageType(item.type) }}</p>
          </b-col>
          <span class="sep">|</span>
          <b-col class="mx-lg-1" v-if="$checkSubUserPermission('dashboard.view-detail')">
            <p class="sub-vin-result">{{ item.description.item.length }} options</p>
          </b-col>
          <b-col v-if="$checkSubUserPermission('dashboard.view-detail')">
            <p class="title-vin-result btn-group">
              <b-button squared active
                        v-if="$store.state.orderSoftware.sofwareTunning.length"
                        :variant="preOrden && preOrden.id === item.id? 'danger' : 'outline-danger'"
                        v-tippy="{ placement : 'top',  arrow: true }"
                        content="use to place in orden the options pre-selected"
                        @click="$emit('select', item)"><i class="material-icons">check</i>
                Use
              </b-button>
              <b-button :to="{name: 'order-detail', params: {id: changeUrl(item.id)}}" target="_blank" variant="outline-danger">
                <i class="material-icons">visibility</i></b-button>
            </p>
          </b-col>
        </b-row>
      </template>
    </b-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {encrypt} from "@/helpers/encrypt";

export default {
  name: 'OrderHistory',
  computed: mapState('orderSoftware', ['preOrden']),
  props: {
    list: []
  },
  methods:{
    changeUrl(id){
      return  encrypt(JSON.stringify({id: id}))
    }
  }
};
</script>

<style scoped lang="scss">
h3 {
  font-family: 'TTnorms';
}

.list-group {
  background: transparent;

  .list-group-item {
    background: transparent;
  }
}

.result-vin-history {
  margin: 1vw auto 20px auto !important;
  text-align: center;

  .fit-center {
    position: relative;
    max-width: fit-content;
    align-items: center;
    margin: 0 auto;
  }

  .sep {
    color: #636363;
    font-style: italic;
  }

  .title-vin-result {
    color: #7d7d7d;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 4px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    margin-bottom: 0;
  }

  p.sub-vin-result {
    border: none;
    padding: 0.4375rem 0.75rem 0.4375rem 0;
    color: rgb(255, 255, 255);
    font-weight: 300;
    font-size: 17px;
    margin: 0;
  }

  .col {
    min-width: fit-content;
  }

  .row {
    border-bottom: 1px solid #636363;
    padding: 5px 0;
  }

  .row:last-child {
    border-bottom: 0;
    margin-bottom: 3vw;
  }
}

.line {
  width: 5%;
  border-top: 1px solid rgb(255, 255, 255);
  margin-top: 7px;
}
</style>
